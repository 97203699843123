import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Showtimes.css'; // Custom styles for the component and popup
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


const Showtimes = () => {
  const [showtimes, setShowtimes] = useState([]);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false); // State for popup visibility
  const [mapDetails, setMapDetails] = useState({ lat: '', lng: '', facilities: [],theatrename:"",address:"" }); // State for map details
  const navigate = useNavigate();

  useEffect(() => {
    const fetchShowtimes = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const movieId = localStorage.getItem('movie_id');

        const response = await axios.get(`${api_url}/getmovie_timeslots/${movieId}`);
        setShowtimes(response.data.showtimes);
      } catch (error) {
        console.error('Error fetching showtimes:', error);
      }
    };

    fetchShowtimes();
  }, []);

  // Handle API call to fetch theater details
  const showmapdetails = async (theatre_id) => {
    try {
      const api_url = localStorage.getItem('api_url');
      const response = await axios.get(`${api_url}/gettheatre_details/${theatre_id}`);
      const { lat, lng, facilities,theatrename,address } = response.data;

      setMapDetails({ lat, lng, facilities,theatrename,address });
      setPopupVisible(true); // Show popup
    } catch (error) {
      console.error('Error fetching theater details:', error);
    }
  };

  // Close the popup
  const closePopup = () => {
    setPopupVisible(false);
    setMapDetails({ lat: '', lng: '', facilities: [] });
  };

  // Handle navigation
  const handleTimeslotClick = (theatre_id, screen_id, date, timeslot_id) => {
    const movie_id = localStorage.getItem('movie_id');
    localStorage.setItem('theatre_id', theatre_id);
    localStorage.setItem('screen_id', screen_id);
    localStorage.setItem('date', date);
    localStorage.setItem('timeslot_id', timeslot_id);
    localStorage.setItem('movie_id', movie_id);

    navigate('/Orderplace', { replace: true });
  };

  return (
    <div className="showtimes-container">
      <div className="date-row">
        {showtimes.map((dateGroup, index) => (
          <button
            key={index}
            className={`date-button ${index === selectedDateIndex ? 'selected' : ''}`}
            onClick={() => setSelectedDateIndex(index)}
          >
            {dateGroup.movie_date}
          </button>
        ))}
      </div>

      <div className="theatre-container">
        {showtimes.length > 0 && showtimes[selectedDateIndex].theatres.map((theatre, theatreIndex) => (
          <div key={theatreIndex} className="theatre-row">
            <div className="theatre-details">
              <h4 className="theatre-name">{theatre.theatre_name}</h4>
              <span onClick={() => showmapdetails(theatre.theatre_id)} className="info-link">
                    Info
                  </span>
              {theatre.screens.map((screen, screenIndex) => (
                <div key={screenIndex} className="screen-details">
                  <h5 className="screen-name">{screen.screen_name}</h5>
                 
                </div>
              ))}
            </div>
            <div className="showtime-slots">
              {theatre.screens.map((screen) => (
                <div key={screen.screen_name} className="screen-showtimes">
                  {screen.showtimes.map((slot, slotIndex) => (
                    <button
                      key={slotIndex}
                      className="showtime-button"
                      onClick={() =>
                        handleTimeslotClick(slot.theatre_id, slot.screen_id, slot.date, slot.timeslot_id)
                      }
                    >
                      <div className="button-content">
                        <span className="showtime">{slot.show_time}</span>
                      </div>
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {popupVisible && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>{mapDetails.theatrename}</h2>
            <button className="close-button" onClick={closePopup}>
              X
            </button>
            <div className="map-container">
              <iframe
                title="Google Maps"
                src={`https://www.google.com/maps?q=${mapDetails.lat},${mapDetails.lng}&hl=es;z=14&output=embed`}
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
              ></iframe>
            </div>
            <p>{mapDetails.address}</p>
            <div className="facilities">
              <h4>Available Facilities:</h4>
              <p>
                {mapDetails.facilities}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Showtimes;
