import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "./Product_detail2.css"; // Assuming this CSS will contain the styles for the design
import { useTranslation } from './TranslationContext';
// import seatIcon from './seat_icon.png';  // Assuming you'll use the proper image for seat
// import standingIcon from './standing_icon.png';  // Assuming you'll use the proper image for standing

const Product_detail2 = () => {
  const [eventDetails, setEventDetails] = useState([]);
  const [eventDetails1, setEventDetails1] = useState(null); // Initialize as null instead of an empty array

  const { translate } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const hall_id = localStorage.getItem('hall_id');
        const event_id = localStorage.getItem('event_id');

        // Fetch hall containers and seat details
        const movieResponse = await axios.get(`${api_url}/getHallcontainers/${hall_id}/${event_id}`);
        setEventDetails(movieResponse.data.hallseats);

        const event_type = localStorage.getItem('event_type');

        // Fetch movie or sport details
        if (event_type === "event") {
          const movieResponse = await axios.get(`${api_url}/getEventdetail/${event_id}`);
          setEventDetails1(movieResponse.data.event);
        } else {
          const movieResponse = await axios.get(`${api_url}/getsportdetail/${event_id}`);
          setEventDetails1(movieResponse.data.sport);
        }
        
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventDetails();
  }, []);

  if (!eventDetails.length || !eventDetails1) {
    return <div>{translate('Loading...')}</div>;
  }

  const handleDivClick = (id) => {
    localStorage.setItem("seat_id", id);
    navigate("/Seatconfirm", { replace: true });
  };
  const formatDate = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  };

  const images = "https://movieticket.riddhi.info:3007/gallery/products/"+eventDetails1.hall_details?.hall_image;

  return (
    <div>
      <div className="event-detail-page">
        {/* Event Banner Section */}
        <div className="event-banner-container">
          <img
          className="event-banner1"
          style={{ width: "50%" }}
          src={images}
          alt="Event Banner"
          />
          </div>

        <div className="event-banner-content">
          <div style={{ display: "flex" }}>
            <div>
              <h3 className="event-title">{eventDetails1?.event_organiser} - {eventDetails1?.event_name}</h3>
              <p className="event-date-time">
              {eventDetails1?.type?.charAt(0).toUpperCase() + eventDetails1?.type?.slice(1)} | {eventDetails1?.hours}
              </p>
            </div>
          </div>

          <hr />
          <p className="event-location">
            {formatDate(eventDetails1?.event_date)} - {eventDetails1?.hall_details?.place || ''} {eventDetails1?.hall_details?.address_detail || ''}
          </p>
        </div>
      </div>

      <br />

      <div className="event-details-container">
        {eventDetails.map((seat, index) => (
          <div key={seat._id} className="seat-container">
            <div className="seat-info">
              <div className="available-count">
                {translate('Available Counts')} {seat.remaining_seats}
              </div>
              <div className="seat-type">
                {/* <img 
                  src={seat.container_name.toLowerCase().includes("seated") ? seatIcon : standingIcon} 
                  alt="seat type icon" 
                  className="seat-icon" 
                /> */}
                {seat.container_name+" Seaction"}
              </div>
            </div>
            <button className="add-button" onClick={() => handleDivClick(seat._id)} disabled={seat.remaining_seats <= 0}>
  {seat.remaining_seats > 0 ? translate('Add') : 'Sold out'}
</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product_detail2;
