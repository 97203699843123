import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';
import "./Product_detail.css";
import "./Showtimes.css";


const TermsPopup = ({ isOpen, onClose, onAccept }) => {
  if (!isOpen) return null;

  return (
    <div className="terms-popup">
      <div className="popup-content">
        <h2>Terms and Conditions</h2>
        <p>
          By booking a ticket, you agree to the following terms and conditions...</p>
        <p>
            All sales are final.
            No refunds will be provided
            Be on time for the show
            We reserve the right to change the schedule
            Any misconduct may result in eviction from the venue
          
        </p>
        <button className='btn btn-primary' onClick={onAccept}>Accept</button>
        <button className='btn btn-danger' style={{marginLeft:"10px"}} onClick={onClose}>Close</button>
      </div>
    </div>
  );
};



const ContainerSelection = () => {
  const [containers, setContainers] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [transactionId, setTransactionId] = useState(''); // To store transaction ID
  const user_id = localStorage.getItem('admin_id'); // Assuming user_id is stored in localStorage
  const api_url = localStorage.getItem('api_url');
  const screen_id = localStorage.getItem('screen_id');//localStorage.getItem('screen_id');
  const date = localStorage.getItem('date'); // Example date
  const movie_id = localStorage.getItem('movie_id');// Example movie ID
  const timeslot_id =localStorage.getItem('timeslot_id'); // Example timeslot ID
  const [movieDetails, setMovieDetails] = useState(null);
  const { translate } = useTranslation();
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [containers2, setContainers2] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    const fetchContainersAndSeats = async () => {
      try {
        const theatre_id = localStorage.getItem('theatre_id');//localStorage.getItem('theatre_id');
        const response = await axios.get(`${api_url}/containers1/${theatre_id}/${screen_id}/${date}/${movie_id}/${timeslot_id}/${user_id}`);
        setContainers(response.data.data);
        setContainers2(response.data.datas[0]);

        const movieResponse = await axios.get(`${api_url}/getmovie_details/${movie_id}`);
        setMovieDetails(movieResponse.data.movie_details);

        const autoseats = await axios.get(`${api_url}/getauto_details/${theatre_id}/${screen_id}/${user_id}`);



        const autoSelectedSeats = autoseats.data.auto_details.flatMap(container => 
          container.seats
            .filter(seat => seat.status === 3) // Ensure that only seats with status 3 are selected
            .map(seat => ({
              containerId: container.id,  // Use container ID
              seatId: seat.seat_id,       // Use seat ID
              price: seat.price || 0      // Ensure price is handled, default to 0 if not available
            }))
        );
  
        // Update selectedSeats and totalPrice based on auto-selected seats 
        setSelectedSeats(autoSelectedSeats);
  
        // Calculate the total price for auto-selected seats
        const initialTotalPrice = autoSelectedSeats.reduce((total, seat) => total + Number(seat.price), 0);

        console.log("total -"+initialTotalPrice);
        setTotalPrice(initialTotalPrice);

      } catch (error) {
        console.error('Error fetching containers or seats:', error); 
      }
    };
    fetchContainersAndSeats();
  }, []);

  if (!movieDetails) {
    return <div>{translate('Loading...')}</div>;
  }


 

  // const handleSeatSelection = (containerId, seatId, price) => {
  //   const isSelected = selectedSeats.some(seat => seat.seatId === seatId);
  //   const numericPrice = Number(price);

  //   if (isSelected) {
  //     setSelectedSeats(prevSelectedSeats => prevSelectedSeats.filter(seat => seat.seatId !== seatId));
  //     setTotalPrice(prevTotalPrice => prevTotalPrice - numericPrice);
  //   } else {
  //     setSelectedSeats(prevSelectedSeats => [...prevSelectedSeats, { containerId, seatId }]);
  //     setTotalPrice(prevTotalPrice => prevTotalPrice + numericPrice);
  //   }
  // };

  const handleSeatSelection = (containerId, seatId, price) => {
    const isSelected = selectedSeats.some(seat => seat.seatId === seatId);
    console.log(isSelected);
    const numericPrice = Number(price);
  
    if (isSelected) {
      // Remove seat from selected seats
      setSelectedSeats(prevSelectedSeats => prevSelectedSeats.filter(seat => seat.seatId !== seatId));
      setTotalPrice(prevTotalPrice => prevTotalPrice - numericPrice);
    } else {
      // Add seat to selected seats
      setSelectedSeats(prevSelectedSeats => [...prevSelectedSeats, { containerId, seatId, price }]);
      setTotalPrice(prevTotalPrice => prevTotalPrice + numericPrice);
    }
  };

  

  const groupSeatsByRow = (seats) => {
    const rows = {};
    seats.forEach((seat) => {
      if (seat.seat_number && typeof seat.seat_number === 'string') {
        const rowLetter = seat.seat_number.charAt(0);
        if (!rows[rowLetter]) {
          rows[rowLetter] = [];
        }
        rows[rowLetter].push(seat);
      } else {
        console.warn(`Seat number is undefined or not a string for seat:`, seat);
      }
    });
    return rows;
  };


  const handleBookNow = () => {
    if (selectedSeats.length === 0) {
      alert('Please select at least one seat.');
      return;
    }
    setIsTermsOpen(true);
  };

  const handleTermsAccept = async () => {
    setIsTermsOpen(false);
    
    const seat_number = selectedSeats.map(seat => seat.seat_number).join(','); // You can store seatId or seat_number based on your need
    const seat_count = selectedSeats.length;

    const seat_ids = selectedSeats.map(seat => seat.seatId).join(','); 
    
    // Store relevant details in localStorage
    localStorage.setItem('tot_amt', totalPrice);
    localStorage.setItem('seat_number', seat_number);
    localStorage.setItem('seat_ids', seat_ids);
    localStorage.setItem('seat_count', seat_count);
    localStorage.setItem('screen_name', containers2.screen_name);
    
    // Navigate to Ordercard
    navigate("/Addcart", { replace: true });
  };
  const handleTermsClose = () => {
    setIsTermsOpen(false);
  };
 
  const processPayment = async () => {

    try {
      // Step 1: Call the paymentorder API to create an order and transaction
      const paymentResponse = await axios.post(`${api_url}/paymentorder`, {
        tot_amt: totalPrice,
        transactionid: transactionId
      });
      const { transactionid, orderId } = paymentResponse.data;
      setTransactionId(transactionid);

      // Step 2: Initiate Razorpay payment
      const options = {
        key: 'rzp_test_JFlMQ0tOgzb3j8', // Example Razorpay test key
        amount: totalPrice * 100, // amount in paise
        currency: 'INR',
        name: 'Medcall',
        description: 'Medcall Transactions',
        image: 'https://example.com/your_logo',
        order_id: orderId,
        handler: async (response) => {
          const { razorpay_payment_id } = response;

          // Step 3: Call the savetransactions API after payment success
          const saveTransactionResponse = await axios.post(`${api_url}/savetransactions`, {
            user_id,
            payment_success_id: razorpay_payment_id,
            status: 'booked',
            transactionid: razorpay_payment_id,
            total_amt: totalPrice
          });

          if (saveTransactionResponse.data) {
            // Step 4: Call the bookings_confirm API after transaction is saved
            const seat_ids = selectedSeats.map(seat => seat.seatId).join(',');

            const confirmBookingResponse = await axios.post(`${api_url}/bookings_confirm`, {
              screen_id,
              timeslot_id,
              movie_id,
              seat_ids,
              total_amount: totalPrice,
              transaction_id: razorpay_payment_id,
              date,
              user_id
            });

            if (confirmBookingResponse.data) {
              alert('Booking confirmed successfully!');
              
              navigate("/Myorders", { replace: true });
            } else {
              alert('Failed to confirm booking.');
            }
          } else {
            alert('Failed to save transaction.');
          }
        },
        prefill: {
          name: "Harsanth", // Example prefill data
          email: "harsanthjack@gmail.com",
          contact: "8667040195"
        },
        theme: {
          color: '#3399cc'
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error during booking process:', error);
      alert('An error occurred while processing your booking.');
    }
  };

  const rows = groupSeatsByRow(containers);

  return (
    <div>

<div className="movie-detail-page">
      {/* Movie Banner */}
      <div className="movie-banner" style={{ backgroundImage: `url(${movieDetails.movie_image})` }}>
        <div className="movie-details-container">
          {/* Movie Image (Left) */}
          <div className="movie-image">
            <img src={movieDetails.movie_image} alt={movieDetails.movie_name} />
          </div>

          {/* Movie Information (Right) */}
          <div className="movie-info">
            <h2>{movieDetails.movie_name}</h2>
            <div className="movie-rating">
              <span>{translate('Rating')}: {movieDetails.ratings} / 10</span>
              <div className="stars">
                {'⭐'.repeat(Math.round(movieDetails.ratings))}
              </div>
            </div>
            <p style={{textAlign:"left"}}> {movieDetails.movie_type}</p>
            <p style={{textAlign:"left"}}> {movieDetails.category}</p>
            <p style={{textAlign:"left"}}> {movieDetails.language}</p>
            <p style={{textAlign:"left"}}> {movieDetails.timing} | {new Date(movieDetails.release_date).toDateString()}</p>
          </div>
        </div>
      </div>

    </div>
    <br></br>
     <div className='theatre_detailsss'>
     <p>{containers2.theatre_name}</p>  <p style={{marginLeft:"20px"}}>{containers2.screen_name}</p>   <p style={{marginLeft:"20px"}}>{containers2.show_time}</p>
     </div>
     <p style={{color:"green",fontSize:"13px"}}>"These seats have already been selected, meaning they are our recommended seats"</p>


    <br></br>
      <div className="container-list">
        {containers.map((container) => (
          <div key={container._id} className="">
            <p className='con_names'>{container.container_name} - ₹{container.price}</p>
            {/* <div className="seats-grid">
              {container.seats && container.seats.length > 0 ? (
                Object.entries(groupSeatsByRow(container.seats)).map(([rowLetter, rowSeats]) => (
                  // <div key={rowLetter} className="seat-row">
                  //   <div className="row-label">{rowLetter}</div>
                  //   {rowSeats.map((seat) => (
                  //     seat.seat_number.toLowerCase().includes('break') ? (
                  //       <div key={seat.seat_id} className="seat break"></div>
                  //     ) : (
                  //       <div
                  //         key={seat.seat_id}
                  //         className={`seat ${selectedSeats.some(s => s.seatId === seat.seat_id) ? 'selected' : ''} ${seat.status === 1 ? 'booked' : seat.status === 2 ? 'available' : seat.status === 3 ? 'selected' : '' }`}
                  //         onClick={() => seat.status === 2 && handleSeatSelection(container._id, seat.seat_id, container.price)}
                  //         style={{
                  //           cursor: seat.status === 2 ? 'pointer' : seat.status === 3 ? 'pointer' : 'not-allowed',
                  //           backgroundColor: seat.status === 1 ? '#eee' : seat.status === 2 ? '' : '',
                  //           color: seat.status === 1 ? '#fff': seat.status === 2 ? '':''
                  //         }}
                  //       >
                  //         {seat.seat_number.substring(1)} 
                  //       </div>
                  //     )
                  //   ))}
                  // </div>

                    <div key={rowLetter} className="seat-row">
                    <div className="row-label">{rowLetter}</div>
                    {rowSeats.map((seat) => (
                    seat.seat_number.toLowerCase().includes('break') ? (
                    <div key={seat.seat_id} className="seat break"></div>
                    ) : (
                    <div
                    key={seat.seat_id}
                    className={`seat ${selectedSeats.some(s => s.seatId === seat.seat_id) ? 'selected' : ''} 
                    ${seat.status === 1 ? 'booked' : seat.status === 2 ? 'available' : seat.status === 3 ? 'selected' : ''}`}
                    onClick={() => (seat.status === 2 || seat.status === 3) && handleSeatSelection(container._id, seat.seat_id, container.price, seat.status)}
                    style={{
                    cursor: (seat.status === 2 || seat.status === 3) ? 'pointer' : 'not-allowed',
                    backgroundColor: seat.status === 1 ? '#eee' : seat.status === 2 || seat.status === 3 ? '' : '',
                    color: seat.status === 1 ? '#fff' : seat.status === 2 || seat.status === 3 ? '' : ''
                    }}
                    >
                    {seat.seat_number.substring(1)}
                    </div>
                    )
                    ))}
                    </div>

                ))
              ) : (
                <p>No seats available for this container</p>
              )}
            </div> */}

<div className="seats-grid">
  {container.seats && container.seats.length > 0 ? (
    Object.entries(groupSeatsByRow(container.seats)).map(([rowLetter, rowSeats]) => (
      <div key={rowLetter} className="seat-row">
        <div className="row-label">{rowLetter}</div>
        {rowSeats.map((seat) => (
          seat.seat_number.toLowerCase().includes('break') ? (
            <div key={seat.seat_id} className="seat break"></div>
          ) : (
            <div
              key={seat.seat_id}
              className={`seat ${selectedSeats.some(s => s.seatId === seat.seat_id) ? 'selected' : ''} ${seat.status === 1 ? 'booked' : seat.status === 2 ? 'available' : ''}`}
              onClick={() => handleSeatSelection(container._id, seat.seat_id, container.price, seat.status)}
              style={{
                cursor: seat.status === 2 ? 'pointer' : seat.status === 3 ? 'pointer' : 'not-allowed',
                backgroundColor: seat.status === 1 ? '#eee' : seat.status === 2 ? '' : '',
                color: seat.status === 1 ? '#fff' : seat.status === 2 ? '' : ''
              }}
            >
              {seat.seat_number.substring(0, seat.seat_number.length)}
            </div>
          )
        ))}
      </div>
    ))
  ) : (
    <div>No seats available.</div>
  )}
</div>

          </div>
        ))}
      </div>

      <div className="screening-image">

        <img alt="Screening" style={{ width: '100%', height: 'auto' }} src={require('./img/screening.png')} />

        <p className="commentary">"All eyes this way, please!"</p>
      </div>

      <div className="seat-legend">
        <div className='leg_c'>
        <div className="seat sold"></div>
       <div className='leg_t'>Sold</div>  
        </div>
        <div className='leg_c'>
        <div className="seat available"></div>
       <div className='leg_t'>Available</div>  
        </div>
        <div className='leg_c'>
        <div className="seat selected"></div>
       <div className='leg_t'>Selected</div>  
        </div>
        
      </div>


      <div className="booking-summary">
        <p>Total Price: ₹{totalPrice}</p>
        <button onClick={handleBookNow}>Book Now</button>
      </div>

      <TermsPopup
        isOpen={isTermsOpen}
        onClose={handleTermsClose}
        onAccept={handleTermsAccept}
      />
    </div>
  );
};

export default ContainerSelection;
